// This is used for the Candidate creation endpoint,
// so it has a required 'undefined' value for the clientId as the default.

import { cookieStorage } from '@nubank/www-latam-commons/utils/storage';

export const getClientId = () => {
  const GA_CLIENT_ID_COOKIE = '_ga';

  const gaClientId = cookieStorage.getString(GA_CLIENT_ID_COOKIE);

  if (gaClientId) {
    return gaClientId.replace(/GA[0-9]+.[0-9]+./, '');
  }

  // Backend needs a string value, so we return 'undefined' as string
  return 'undefined';
};
