const NOT_A_NUMBER_REGEX = /\D/g;

/**
 * Applies formatting to a Mexican phone number
 * Formats as: XX XXXXXXXX (e.g., "55 12345678")
 * @param {string} value - The input phone number string
 * @returns {string} The formatted phone number
 */
export const formatPhoneNumber = value => {
  const valueWithOnlyNumbers = value.replace(NOT_A_NUMBER_REGEX, '');
  let maskedValue = valueWithOnlyNumbers;

  if (value.length >= 3) {
    maskedValue = `${maskedValue.substring(0, 2)} ${maskedValue.substring(2, maskedValue.length)}`;

    if (value.length >= 8) {
      maskedValue = `${maskedValue.substring(0, 7)} ${maskedValue.substring(7, maskedValue.length)}`;
    }
  }
  return maskedValue.trim();
};
