import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';

import { getClientId } from '../../utils/getGoogleAnalyticsClientId';

export const registerCandidateData = async (email, urls) => {
  if (!email) {
    throw new Error('Email is required to register candidate');
  }

  const discoveryURLs = urls || await fetchDiscoveryURLs();

  // eslint-disable-next-line camelcase
  if (!discoveryURLs?.register_candidate) {
    throw new Error('Discovery fetch response unexpected - missing endpoint');
  }

  const payload = {
    email,
    origin: 'global-web',
    device_id: getClientId(),
  };

  const result = await fetchJson(discoveryURLs.register_candidate, {
    method: 'POST',
    body: payload,
  });

  return result;
};

