import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

export const StyledDrawer = styled(Drawer)`
  #new-rfc-drawer {
    bottom: 0;
    top: auto;
    height: 400px;
    border-radius: 16px 16px 0 0;
    padding: 12px;
  }
`;

export const StyledTitle = styled(Typography)`
  font-size: 24px;
`;

export const UpdateEmailBox = styled(Box)`
  svg {
    display: none;
  }

  input {
    border-bottom: ${props => (props.wasEmailUpdated ? '1px solid auto' : '1px solid #D72923')};
    caret-color: ${props => (props.wasEmailUpdated ? 'auto' : '#D72923')};
    color: ${props => (props.wasEmailUpdated ? 'auto' : '#D72923')};
  }
`;

export const ErrorMessage = styled(Typography)`
  color: #D72923;
  font-size: 14px;
  font-weight: bold;
  margin-top: -30px;
`;
