import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';
import { getMarketingData } from '@nubank/www-latam-commons/utils/marketingUtils';

import { getClientId } from '../../utils/getGoogleAnalyticsClientId';

//                                          //
//  NEW VERSION OF THE CANDIDATE CREATION   //
//            ENDPOINT                    //

export const registerCandidateDataV2 = async (email, urls) => {
  if (!email) {
    throw new Error('Email is required to register candidate');
  }

  const discoveryURLs = urls || await fetchDiscoveryURLs();

  // eslint-disable-next-line camelcase
  if (!discoveryURLs?.register_candidate_web) {
    throw new Error('Discovery fetch response unexpected - missing endpoint on discoveryURLs');
  }

  const { parameters } = getMarketingData();

  const payload = {
    email,
    session_id: getClientId(),
    web_acquisition_params: parameters || ' ',
  };

  const result = await fetchJson(discoveryURLs.register_candidate_web, {
    method: 'POST',
    body: payload,
  });

  // It returns the created candidate id
  return result;
};
