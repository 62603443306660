import styled, { css } from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const StyledTitle = styled(Typography)`
  font-size: 2rem;

  @media (width >= 768px) {
    font-size: 2.5rem;
  }
`;

export const StyledSection = styled(Box)`
  button {
    min-width: 64px;
    height: 64px;
    margin-left: 18px;
  }

  span {
    color: #820AD1;
    font-weight: bold;
  }
`;

export const StyledContainer = styled(Box)`
  ${breakpointsMedia({
    xs: css`
      padding: 2px 1.5rem 1.5rem;
      `,
    lg: css`
      padding: 2px 2.5rem 2.5rem;
    `,
  })}
`;

export const StyledRedirectionTitle = styled(Typography)`
  font-size: 2rem;

  @media (width >= 768px) {
    font-size: 2.5rem;
  }
`;

export const StyledRedirectionSection = styled(Box)`
  button {
    min-width: 64px;
    height: 64px;
    margin-left: 18px;
  }

  span {
    color: #820AD1;
    font-weight: bold;
  }
`;
